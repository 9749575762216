<template>
  <common-warpper title="关于云联" en="ABOUT GUOLIAN" :imgSrc="banner">
    <div class="info" v-if="pageData.about">
      <div v-html="pageData.about.content"></div>
    </div>
  </common-warpper>
</template>
<script>
import CommonWarpper from "@/components/CommonWarpper.vue";
import banner from "@/assets/images/home/banner2.jpg";
import { getIndex } from "@/api/index";

export default {
  name: "About",
  components: {
    CommonWarpper,
  },
  data() {
    return {
      banner,
      pageData: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getIndex().then((res) => {
        this.pageData = res.data;
      });
    },
  },
};
</script>
